export const PROJECT_ID = '1ecd2300c3098e2d6571be046da01a58'
export const CHAINS = [
  /*
  {
    chainId: 56,
    name: 'BNB Smart Chain Mainnet',
    currency: 'BNB',
    explorerUrl: 'https://bscscan.com/',
    rpcUrl: 'https://bsc-dataseed1.binance.org/'
  },
  */
  {
    chainId: 97,
    name: 'BNB Smart Chain Testnet',
    currency: 'BNB',
    explorerUrl: 'https://testnet.bscscan.com',
    rpcUrl: 'https://data-seed-prebsc-1-s1.binance.org:8545'
  }
]
export const METADATA = {
  name: 'Notario',
  description: 'Notario',
  url: '',
  icons: []
}
export const CURRENCY_ICONS = [
  {
    currency: 'BNB',
    icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.611178 36"><g transform="translate(-522.6,-394.9)" class="fill"><path d="M 526.04555,412.9 L 526.06454,419.86284 L 531.98085,423.34426 V 427.42069 L 522.60211,421.92004 V 410.8639 Z M 526.04555,405.93716 V 409.9946 L 522.6,407.95638 V 403.89895 L 526.04555,401.86073 L 529.50798,403.89895 Z M 534.4516,403.89895 L 537.89715,401.86073 L 541.35958,403.89895 L 537.89715,405.93716 Z" /><path d="M 528.53529,418.41963 V 414.34321 L 531.98085,416.38142 V 420.43886 Z M 534.4516,424.80435 L 537.89715,426.84256 L 541.35958,424.80435 V 428.86179 L 537.89715,430.9 L 534.4516,428.86179 Z M 546.30109,403.89895 L 549.74664,401.86073 L 553.20907,403.89895 V 407.95638 L 549.74664,409.9946 V 405.93716 Z M 549.74664,419.86284 L 549.76563,412.9 L 553.21118,410.86179 V 421.91793 L 543.83245,427.41858 V 423.34215 Z" /><polygon points="1528.8,1605.3 1528.8,1413 1692.1,1316.4 1692.1,1509.6 " transform="matrix(0.02109952,0,0,0.02109952,511.57339,386.5678)" /><path d="M 547.27589,407.38037 L 547.29488,411.45679 L 541.36169,414.93821 V 421.91793 L 537.91614,423.93716 L 534.47059,421.91793 V 414.93821 L 528.5374,411.45679 V 407.38037 L 531.99773,405.34215 L 537.89504,408.84045 L 543.82823,405.34215 L 547.29066,407.38037 Z M 528.53529,400.41963 L 537.89715,394.9 L 547.27589,400.41963 L 543.83034,402.45785 L 537.89715,398.95955 L 531.98085,402.45785 Z" /></g></svg>'
  }
]

export const NULL_ADDRESS = '0x0000000000000000000000000000000000000000'

export const IPFS_BASEURL = 'https://gw3.io/ipfs/'
export const IPFS_ACCESSKEY = 'c7a2f74c-420b-4295-90a3-6017afa8012f'
export const IPFS_SECRETKEY = 'wY6JeRkXgq1fQzRmLIdMbIgW96r5nxl9BIKpfy4nwLOgKQfJbOiP8TNWyAvRiqZiEj2pCx50CzNROMM9WyTOoQ=='

// export const NOTARIO_OWNER = '0x4F4D915f78DB9b24A6768068C34D3CD1c732e8Ff'
export const NOTARIO_ADDRESS = '0x8bBbC0f8e74106984584bEfDF7600F3864f06cA0'
export const NOTARIO_ABI = [
  {
    "inputs": [],
    "stateMutability": "nonpayable",
    "type": "constructor"
  },
  {
    "inputs": [],
    "name": "NotAuthorized",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "StorageFull",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "ZeroAddress",
    "type": "error"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "cntrAddr",
        "type": "address"
      }
    ],
    "name": "AgreementCreated",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "cntrAddr",
        "type": "address"
      }
    ],
    "name": "AgreementDeleted",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "previousOwner",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "newOwner",
        "type": "address"
      }
    ],
    "name": "OwnershipTransferred",
    "type": "event"
  },
  {
    "inputs": [
      {
        "internalType": "int256",
        "name": "",
        "type": "int256"
      }
    ],
    "name": "contratos",
    "outputs": [
      {
        "internalType": "contract Contrato",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "contratosCnt",
    "outputs": [
      {
        "internalType": "int256",
        "name": "",
        "type": "int256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "owner",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_partB",
        "type": "address"
      },
      {
        "internalType": "string",
        "name": "_name",
        "type": "string"
      },
      {
        "internalType": "string",
        "name": "_description",
        "type": "string"
      },
      {
        "internalType": "string",
        "name": "_ipfsHash",
        "type": "string"
      }
    ],
    "name": "CreateContrato",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "int256",
        "name": "index",
        "type": "int256"
      }
    ],
    "name": "DeleteContrato",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "newOwner",
        "type": "address"
      }
    ],
    "name": "transferOwnership",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  }
]

export const CONTRATO_ABI = [
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_partA",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "_partB",
        "type": "address"
      },
      {
        "internalType": "string",
        "name": "_name",
        "type": "string"
      },
      {
        "internalType": "string",
        "name": "_description",
        "type": "string"
      },
      {
        "internalType": "string",
        "name": "_ipfsHash",
        "type": "string"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "constructor"
  },
  {
    "inputs": [],
    "name": "AlreadySigned",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "EmptyName",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "NotAuthorized",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "ZeroAddress",
    "type": "error"
  },
  {
    "anonymous": false,
    "inputs": [],
    "name": "AgreementSignedByA",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [],
    "name": "AgreementSignedByB",
    "type": "event"
  },
  {
    "inputs": [],
    "name": "SignedByA",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "partASignedTimestamp",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "SignedByB",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "partBSignedTimestamp",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "SignedByBoth",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "partASignedTimestamp",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "partBSignedTimestamp",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "GetInfo",
    "outputs": [
      {
        "internalType": "address",
        "name": "_partA",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "_partB",
        "type": "address"
      },
      {
        "internalType": "string",
        "name": "_name",
        "type": "string"
      },
      {
        "internalType": "string",
        "name": "_description",
        "type": "string"
      },
      {
        "internalType": "string",
        "name": "_ipfsHash",
        "type": "string"
      },
      {
        "internalType": "uint256",
        "name": "_signedA",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "_signedB",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "Sign",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  }
]