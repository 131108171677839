import './App.css'
import { useEffect, useState } from 'react'
import Web3 from 'web3'
import {
  createWeb3Modal,
  defaultConfig,
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalEvents,
  useWeb3ModalState
} from '@web3modal/ethers5/react'
import { Client } from 'gw3-sdk'
import {
  PROJECT_ID,
  METADATA,
  CHAINS,
  CURRENCY_ICONS,
  NULL_ADDRESS,
  // NOTARIO_OWNER,
  NOTARIO_ADDRESS,
  NOTARIO_ABI,
  CONTRATO_ABI,
  IPFS_BASEURL,
  IPFS_ACCESSKEY,
  IPFS_SECRETKEY
} from './config'
import { convertTimestamp, shortAddr } from './utils'

createWeb3Modal({
  projectId: PROJECT_ID,
  ethersConfig: defaultConfig({ METADATA }),
  chains: CHAINS,
  featuredWalletIds: [
    '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0',
    '19177a98252e07ddfc9af2083ba8e07ef627cb6103467ffebb3f8f4205fd7927',
    '8a0ee50d1f22f6651afcae7eb4253e52a3310b90af5daef78a8c4929a9bb99d4'
  ],
  themeMode: 'dark'
})

// let web3
let contratoObjectArr = []
let contratoInfoArr = []

const filterOptions = [
  'All contratos',
  'Signed both',
  'Not signed A',
  'Not signed B'
]

const App = () => {
  const { open } = useWeb3Modal()
  const { address, isConnected } = useWeb3ModalAccount()
  const { selectedNetworkId } = useWeb3ModalState()
  const events = useWeb3ModalEvents()

  const [web3, setWeb3] = useState(null)
  const [loaded, setLoaded] = useState(false)
  const [account, setAccount] = useState(null)
  const [balance, setBalance] = useState('')
  const [currency, setCurrency] = useState(null)
  const [notario, setNotario] = useState(null)
  const [notarioOwner, setNotarioOwner] = useState(null)
  const [contratoCnt, setContratoCnt] = useState(0)
  const [contratoObject, setContratoObject] = useState(null)
  const [contratoInfo, setContratoInfo] = useState(null)
  const [addVisible, setAddVisible] = useState(false)
  const [partBValue, setPartBValue] = useState('')
  const [nameValue, setNameValue] = useState('')
  const [descriptionValue, setDescriptionValue] = useState('')
  const [ipfsHashValue, setIpfsHashValue] = useState('')
  const [fileValue, setFileValue] = useState('')
  const [fileBinary, setFileBinary] = useState(null)
  const [fileLoading, setFileLoading] = useState(false)
  const [signLoading, setSignLoading] = useState(null)
  const [removeLoading, setRemoveLoading] = useState(null)
  const [removeDone, setRemoveDone] = useState(null)
  const [addLoading, setAddLoading] = useState(null)
  const [filter, setFilter] = useState(0)
  const [filterOpened, setFilterOpened] = useState(false)

  const load = async () => {
    await loadWeb3()
  }

  const loadWeb3 = async () => {
    if (window.ethereum) {
      window.ethereum.on('accountsChanged', handleAccountsChanged)
      setWeb3(new Web3(window.ethereum))
      setLoaded(true)
    }
  }

  const estimateGas = async (trxParams, onSuccess, onError) => {
    web3.eth.estimateGas(trxParams)
      .then((gasVal) => {
        console.log('Estimate gas value:', gasVal)
        const gasValInt = parseInt(gasVal, 16)
        if (gasValInt > 0) {
          trxParams.gas = parseInt(gasValInt * 1.0).toString()
        }
        if (typeof onSuccess === 'function') {
          onSuccess(trxParams)
        }
      })
      .catch((error) => {
        console.log('Estimate gas failed:', error)
        if (typeof onError === 'function') {
          onError()
        }
      })
  }

  const getBalance = async () => {
    web3.eth.getBalance(account)
      .then((receipt) => {
        console.log('Get balance success:', receipt)
        setBalance((receipt / Math.pow(10, 18)))
      })
      .catch((error) => {
        console.log('Get balance error:', error)
      })
  }

  const notarioCreate = async () => {
    console.log('Create notario.')
    const notarioObj = new web3.eth.Contract(NOTARIO_ABI, NOTARIO_ADDRESS)
    setNotario(notarioObj)
  }

  const notarioGetOwner = async () => {
    console.log('Get notario owner...')
    // setNotarioOwner(NOTARIO_OWNER)
    notario.methods.owner().call()
      .then((owner) => {
        if (owner) {
          console.log('Get notario owner success:', owner)
          setNotarioOwner(owner)
        }
      })
      .catch((error) => {
        console.log('Get notario owner error:', error)
      })
  }

  const notarioGetContratos = async () => {
    console.log('Get contratos...')
    contratoObjectArr = []
    contratoInfoArr = []
    notario.methods.contratosCnt().call()
      .then((cnt) => {
        console.log('Get contratos cnt success:', cnt)
        setContratoCnt(cnt)
        for (let i = 1; i <= cnt; i++) {
          notario.methods.contratos(i).call()
            .then((item) => {
              if (item === NULL_ADDRESS) {
                console.log('Skip contrato #' + i + ' (' + item + ').')
              } else {
                contratoCreate(item, i)
              }
            })
            .catch((error) => {
              console.log('Get contrato ' + i + ' error:', error)
            })
        }
      })
      .catch((error) => {
        console.log('Get contratos cnt error:', error)
      })
  }

  const notarioCreateContrato = async () => {
    setAddLoading(true)
    let params = {
      partB: partBValue,
      name: nameValue,
      description: descriptionValue,
      ipfsHash: ipfsHashValue
    }
    const ind = parseInt(contratoCnt) + 1

    const trxParams = {
      to: notario._address,
      from: account,
      data: notario.methods.CreateContrato(...Object.values(params)).encodeABI(),
    }
    estimateGas(trxParams, (trxParams) => {
      console.log('Add contrato #' + ind + '...', params)
      web3.eth.sendTransaction(trxParams)
        .on('transactionHash', (trxHash) => {
          console.log('Add contrato #' + ind + ' transactionHash:', trxHash)
        })
        .on('receipt', ({ logs }) => {
          let addr = '0x' + logs[0]?.topics[1]
          if (addr) {
            addr = '0x' + addr.slice(-40)
            console.log('Add contrato #' + ind + ' success:', addr)
            contratoCreate(addr, ind)
            getBalance()
            setAddVisible(false)
            setPartBValue('')
            setNameValue('')
            setDescriptionValue('')
            setFileValue('')
            setFileBinary(null)
            setIpfsHashValue('')
          } else {
            console.log('Add contrato' + ind + ' error: no returned address.')
          }
          setAddLoading(false)
        })
        .on('error', (error) => {
          console.log('Add contrato #' + ind + ' failed:', error)
          setAddLoading(false)
        })
    }, () => {
      setAddLoading(false)
    })
  }

  const notarioRemoveContrato = async (e, ind) => {
    e.preventDefault()
    console.log('Remove contrato #' + ind + ' by ' + account + ' ...')
    setRemoveLoading(ind)

    const trxParams = {
      to: notario._address,
      from: account,
      data: notario.methods.DeleteContrato(ind).encodeABI(),
    }
    estimateGas(trxParams, (trxParams) => {
      web3.eth.sendTransaction(trxParams)
        .on('transactionHash', (trxHash) => {
          console.log('Remove contrato #' + ind + ' transactionHash:', trxHash)
        })
        .on('receipt', ({ logs }) => {
          let addr = '0x' + logs[0]?.topics[1]
          if (addr) {
            addr = '0x' + addr.slice(-40)
            console.log('Remove contrato #' + ind + ' success:', addr)
            setRemoveDone(ind)
            setTimeout(() => {
              contratoRemove(ind)
              getBalance()
              setRemoveLoading(null)
              setRemoveDone(null)
            }, 500)
          } else {
            console.log('Remove contrato #' + ind + ' error: no returned address.')
            setRemoveLoading(null)
          }
        })
        .on('error', (error) => {
          console.log('Remove contrato #' + ind + ' failed:', error)
          setRemoveLoading(null)
        })
    }, () => {
      setRemoveLoading(null)
    })
  }

  const contratoCreate = (addr, ind) => {
    console.log('Create contrato #' + ind + ' (' + addr + ') ...')
    const contratoObj = new web3.eth.Contract(CONTRATO_ABI, addr)
    contratoObjectArr = [ ...contratoObjectArr, { ind: ind, value: contratoObj } ]
    console.log('Create contrato #' + ind + ' success:', contratoObj)
    setContratoObject(contratoObjectArr)
    contratoGetInfo(contratoObj, ind)
  }

  const contratosGetInfo = () => {
    contratoInfoArr = []
    const cnt = contratoObject.length
    if (cnt > 0) {
      console.log('Get ' + cnt + ' contratos info...')
      contratoObject.map(async (el) => {
        contratoGetInfo(el.value, el.ind)
      })
    }
  }

  const contratoGetInfo = async (contratoObj, ind) => {
    console.log('Get contrato #' + ind + ' info...')
    contratoObj.methods.GetInfo().call({ from: account })
      .then((info) => {
        if (info && (account.toLowerCase() === info._partA.toLowerCase() || account.toLowerCase() === info._partB.toLowerCase())) {
          console.log('Get contrato #' + ind + ' info success:', info)
          const newContratoInfoArr = contratoInfoArr.find(el => parseInt(el.ind) === parseInt(ind))
            ? contratoInfoArr.map(el => parseInt(el.ind) === parseInt(ind) ? { ind: ind, value: info } : el)
            : [ ...contratoInfoArr, { ind: ind, value: info } ]
          contratoInfoArr = newContratoInfoArr
          setContratoInfo(contratoInfoArr)
        }
      })
      .catch((error) => {
        console.log('Get contrato #' + ind + ' info error:', error)
          const newContratoInfoArr = contratoInfoArr.find(el => parseInt(el.ind) === parseInt(ind))
            ? contratoInfoArr.map(el => parseInt(el.ind) === parseInt(ind) ? { ind: ind, value: 'reverted' } : el)
            : [ ...contratoInfoArr, { ind: ind, value: 'reverted' } ]
        contratoInfoArr = newContratoInfoArr
        setContratoInfo(contratoInfoArr)
      })
  }

  const contratoSign = async (e, ind, letter) => {
    e.preventDefault()
    console.log('Sign contrato #' + ind + ' by ' + account + ' ...')
    setSignLoading(ind + letter)
    const contratoObj = contratoObject.find(el => parseInt(el.ind) === parseInt(ind)).value

    const trxParams = {
      to: contratoObj._address,
      from: account,
      data: contratoObj.methods.Sign().encodeABI(),
    }
    estimateGas(trxParams, (trxParams) => {
      web3.eth.sendTransaction(trxParams)
        .on('transactionHash', (trxHash) => {
          console.log('Sign contrato #' + ind + ' transactionHash:', trxHash)
        })
        .on('receipt', (receipt) => {
          console.log('Sign contrato #' + ind + ' receipt:', receipt)
          contratoGetInfo(contratoObj, ind)
          getBalance()
          setSignLoading(null)
          document.querySelector('.contrato[data-index="' + ind + '"] .part[data-letter="' + letter + '"]').classList.add('headShake')
          setTimeout(() => {
            document.querySelector('.contrato[data-index="' + ind + '"] .part[data-letter="' + letter + '"]').classList.remove('headShake')
          }, 1000)
        })
        .on('error', (error) => {
          console.log('Sign contrato #' + ind + ' failed:', error)
          setSignLoading(null)
        })
    }, () => {
      setSignLoading(null)
    })
  }

  const contratoRemove = (ind) => {
    const newContratoObjectArr = contratoObjectArr.filter(el => parseInt(el.ind) !== parseInt(ind))
    contratoObjectArr = newContratoObjectArr
    setContratoObject(contratoObjectArr)
    const newContratoInfoArr = contratoInfoArr.filter(el => parseInt(el.ind) !== parseInt(ind))
    contratoInfoArr = newContratoInfoArr
    setContratoInfo(contratoInfoArr)
  }

  const isSigned = (signed) => {
    return (signed && signed.toString() !== '0')
  }

  const uploadFile = async () => {
    console.log('Upload file...', fileBinary)
    setFileLoading(true)
    const client = new Client(IPFS_ACCESSKEY, IPFS_SECRETKEY)
    const hooks = {
      onSuccess: (body) => {
        console.log('Upload file success:', body)
        client.addPin(body.cid)
          .then(() => {
            console.log('File with CID ' + body.cid + ' has been pinned successfully')
            setFileValue('')
            setFileBinary(null)
            setIpfsHashValue(body.cid)
            setFileLoading(false)
          })
          .catch((error) => {
            console.log('Error in pinning file with CID ' + body.cid + ':', error)
            setFileLoading(false)
          })
      },
      onError: (error) => {
        console.log('Upload file error:', error)
        setFileLoading(false)
      }
    }
    client.uploadFile(fileBinary, hooks)
  }

  const handleAccountsChanged = (accounts) => {
    if (!account || !accounts[0] || account.toLowerCase() !== accounts[0].toLowerCase()) {
      setAccount(accounts[0])
    }
  }

  useEffect(() => {
    if (!loaded) {
      load()
    }
  })

  useEffect(() => {
    const name = events.data.event
    console.log('Web3modal Event:', name)
  }, [events])

  useEffect(() => {
    if (web3 && !notario) {
      notarioCreate()
    }
  }, [web3])

  useEffect(() => {
    if (!isConnected) {
      contratoInfoArr = {}
      setAccount(null)
      setContratoInfo(null)
    }
  }, [isConnected])

  useEffect(() => {
    if (selectedNetworkId) {
      const chain = CHAINS.find(ch => ch.chainId.toString() === selectedNetworkId.toString())
      if (chain) {
        setCurrency(chain.currency)
      } else {
        setCurrency(null)
      }
    } else {
      setCurrency(null)
    }
  }, [selectedNetworkId])

  useEffect(() => {
    if (!account || !address || account.toLowerCase() !== address.toLowerCase()) {
      setAccount(address)
    }
  }, [address])

  useEffect(() => {
    console.log('Account changed to:', account)
    if (account && notario) {
      getBalance()
      if (contratoObject) {
        contratosGetInfo()
      } else {
        notarioGetContratos()
      }
    } else if (!account) {
      contratoInfoArr = {}
      setContratoInfo(null)
    }
  }, [account])

  useEffect(() => {
    if (notario) {
      if (!notarioOwner) {
        notarioGetOwner()
      }
      if (account && !contratoObject) {
        notarioGetContratos()
      }
    }
  }, [notario])

  const connectParams = CHAINS.length > 1 ? { view: 'Networks' } : {}

  const contratoObjectList = contratoObject ? contratoObject.sort((a, b) => parseInt(a.ind) < parseInt(b.ind) ? 1 : parseInt(a.ind) > parseInt(b.ind) ? -1 : 0) : []
  let contratoInfoList = []
  let contratoInfoCnt = 0
  let contratoInfoCntFiltered = 0
  if (contratoInfo) {
    contratoInfoList = contratoInfo.filter(el => el.value !== 'reverted')
    contratoInfoCnt = contratoInfoList.length
    if (filter) {
      contratoInfoList = contratoInfoList.filter(el => {
        if (filter === 1) return isSigned(el.value._signedA) && isSigned(el.value._signedB)
        else if (filter === 2) return !isSigned(el.value._signedA)
        else if (filter === 3) return !isSigned(el.value._signedB)
        else return true
      })
    }
    contratoInfoCntFiltered = contratoInfoList.length
  }

  return (
    <div className="app" onClick={() => { setAddVisible(false); setFilterOpened(false) }} data-theme="dark">
      <div className="blocks">
        {(isConnected && account) && <div className="block">
          <div className="title center" data-network={selectedNetworkId}>{shortAddr(account)}</div>
          <div className="balance" dangerouslySetInnerHTML={{ __html: balance ? (balance + (currency ? (' ' + CURRENCY_ICONS.find(el => el.currency === currency)?.icon) : '')) : ''}} />
        </div>}
        <div className="block controls">
          {isConnected
            ? <>
                <button onClick={() => open()}>Change Wallet</button>
                {account && <>
                  {(notario && notarioOwner && account.toLowerCase() === notarioOwner.toLowerCase()) 
                    ? <>
                        <button onClick={(e) => { e.stopPropagation(); setAddVisible(true)}}>Create Contrato</button>
                        <div className="form zoomIn" data-visible={addVisible} onClick={(e) => e.stopPropagation()}>
                          <div className="title">Create Contrato</div>
                          <div className="field">
                            <div className="label">participant B:</div>
                            <input type="text" name="partB" value={partBValue} placeholder="address" onChange={(e) => setPartBValue(e.target.value)} />
                          </div>
                          <div className="field">
                            <div className="label">name:</div>
                            <input type="text" name="name" value={nameValue} placeholder="text" onChange={(e) => setNameValue(e.target.value)} />
                          </div>
                          <div className="field">
                            <div className="label">description:</div>
                            <input type="text" name="description" value={descriptionValue} placeholder="text" onChange={(e) => setDescriptionValue(e.target.value)} />
                          </div>
                          <div className="field file">
                            <div className="label">file (optional):</div>
                            {ipfsHashValue
                              ? <div className="label">{ipfsHashValue}</div>
                              : <>
                                  <input type="file" name="file" value={fileValue} onChange={(e) => {
                                    setFileValue(e.target.value)
                                    setFileBinary(e.target.files[0])
                                  }} />
                                  <button onClick={() => uploadFile()} disabled={!fileBinary}><span className={fileLoading ? 'flash' : ''}>{fileLoading ? 'loading...' : 'Upload File'}</span></button>
                                </>
                            }
                          </div>
                          <div className="field buttons">
                            {addLoading
                              ? <div className="note flash">creating contrato...</div>
                              : <>
                                  <button onClick={() => setAddVisible(false)} disabled={fileLoading}>Cancel</button>
                                  <button type="submit" onClick={() => notarioCreateContrato()} disabled={!partBValue || !nameValue || !descriptionValue || fileLoading}>Create</button>
                                </>
                            }
                          </div>
                        </div>
                      </>
                    : <div />
                  }
                </>}
              </>
            : <button onClick={() => open(connectParams)}>Connect Wallet</button>
          }
        </div>
        {(isConnected && account && notario) && <>
          {(contratoInfo && contratoInfoCnt > 0)
            ? <div className="block">
                <div className="contratos-list-title fadeInUp">
                  <span className="title">Contratos</span>
                  <span className="contratos-count">/{contratoInfoCntFiltered + ' of ' + contratoInfoCnt}/</span>

                  <div className="select">
                    <div className="selected" onClick={(e) => { e.stopPropagation(); setFilterOpened(!filterOpened) }}>
                      <span>{filterOptions[filter]}</span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512"><path d="M510.078,35.509c-3.388-7.304-10.709-11.977-18.761-11.977H20.682c-8.051,0-15.372,4.672-18.761,11.977 s-2.23,15.911,2.969,22.06l183.364,216.828v146.324c0,7.833,4.426,14.995,11.433,18.499l94.127,47.063 c2.919,1.46,6.088,2.183,9.249,2.183c3.782,0,7.552-1.036,10.874-3.089c6.097-3.769,9.809-10.426,9.809-17.594V274.397 L507.11,57.569C512.309,51.42,513.466,42.813,510.078,35.509z M287.27,253.469c-3.157,3.734-4.889,8.466-4.889,13.355V434.32 l-52.763-26.381V266.825c0-4.89-1.733-9.621-4.89-13.355L65.259,64.896h381.482L287.27,253.469z" className="fill"/></svg>
                    </div>
                    {filterOpened && <div className="options fadeInDown" onClick={(e) => e.stopPropagation()}>
                      {filterOptions.map((el, key) => 
                        <div key={key} data-active={filter === key} onClick={() => {setFilter(key); setFilterOpened(false)}}>{el}</div>
                      )}
                    </div>}
                  </div>
                </div>
                <div className="contratos-list">
                  {contratoObjectList.map((el, i) => {
                    const ind = el.ind
                    let info = contratoInfoList.find(v => parseInt(v.ind) === parseInt(ind))
                    info = (typeof info !== 'undefined') ? info.value : null
                    if (info === 'reverted' || info === null) {
                      return null
                    } else if (info !== null) {
                      return <div className={'contrato fadeInUp' + (removeDone === ind ? ' zoomOutUp' : '')} key={i} data-index={ind} data-disabled={removeLoading === ind || signLoading === ind + 'A' || signLoading === ind + 'B'}>
                          {(notarioOwner && account.toLowerCase() === notarioOwner.toLowerCase()) && <div className="controls">
                            {removeLoading === ind
                              ? <span className="label flash">removing...</span>
                              : <span className="remove" onClick={(e) => notarioRemoveContrato(e, ind)}><svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512"><g className="fill"><path d="M467.786,94.127H44.214c-11.422,0-20.682,9.26-20.682,20.682s9.26,20.682,20.682,20.682h423.572 c11.423,0,20.682-9.26,20.682-20.682S479.209,94.127,467.786,94.127z"/><path d="M420.722,94.127c-11.423,0-20.682,9.26-20.682,20.682v329.445c0,14.547-11.835,26.381-26.381,26.381H138.341 c-14.547,0-26.381-11.835-26.381-26.381V114.809c0-11.422-9.26-20.682-20.682-20.682c-11.422,0-20.682,9.26-20.682,20.682v329.445 c0,37.355,30.39,67.746,67.746,67.746h235.318c37.355,0,67.746-30.391,67.746-67.746V114.809 C441.405,103.387,432.145,94.127,420.722,94.127z"/><path d="M303.064,0h-94.127c-37.355,0-67.746,30.39-67.746,67.746v47.064c0,11.422,9.26,20.682,20.682,20.682 c11.422,0,20.682-9.26,20.682-20.682V67.746c0-14.547,11.835-26.381,26.381-26.381h94.127c14.547,0,26.381,11.835,26.381,26.381 v47.064c0,11.422,9.259,20.682,20.682,20.682c11.423,0,20.682-9.26,20.682-20.682V67.746C370.809,30.391,340.419,0,303.064,0z"/><path d="M208.936,211.786c-11.422,0-20.682,9.26-20.682,20.682v141.191c0,11.423,9.26,20.682,20.682,20.682 s20.682-9.259,20.682-20.682V232.468C229.619,221.046,220.359,211.786,208.936,211.786z"/><path d="M303.064,211.786c-11.423,0-20.682,9.26-20.682,20.682v141.191c0,11.423,9.259,20.682,20.682,20.682 c11.423,0,20.682-9.259,20.682-20.682V232.468C323.746,221.046,314.487,211.786,303.064,211.786z"/></g></svg></span>
                            }
                          </div>}
                          <div className="head">
                            {info._ipfsHash && <span className="cell">
                              <a href={IPFS_BASEURL + info._ipfsHash} target="_blank" rel="noreferrer" className="doc">
                                <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512"><g className="fill"><path d="M458.879,170.78L294.156,6.058C290.277,2.179,285.018,0,279.532,0H114.809C77.454,0,47.064,30.391,47.064,67.746v376.508 c0,37.355,30.391,67.746,67.746,67.746h282.381c37.355,0,67.746-30.391,67.746-67.746v-258.85 C464.936,179.92,462.758,174.659,458.879,170.78z M423.572,444.254c0,14.547-11.835,26.381-26.381,26.381H114.809 c-14.547,0-26.381-11.835-26.381-26.381V67.746c0-14.547,11.835-26.381,26.381-26.381h156.155l152.607,152.607V444.254z"/><path d="M444.254,164.722h-144.04V20.682C300.214,9.26,290.955,0,279.532,0S258.85,9.26,258.85,20.682v164.722 c0,11.422,9.259,20.682,20.682,20.682h164.722c11.423,0,20.682-9.26,20.682-20.682S455.677,164.722,444.254,164.722z"/></g></svg>
                              </a>
                            </span>}
                            <span className="cell">
                              <span className="label">name:</span>
                              <span className="value">{info._name}</span>
                            </span>
                          </div>
                          <div className="part" data-letter="A" data-signed={isSigned(info._signedA)}>
                            <span className="cell">
                              <span className="label">participant A:</span>
                              <span className="value">{shortAddr(info._partA)}</span>
                            </span>
                            {isSigned(info._signedA)
                              ? <span className="cell">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512"><path d="M505.943,79.594c-8.077-8.077-21.172-8.077-29.249,0L167.755,388.532L35.306,256.083c-8.076-8.077-21.172-8.077-29.248,0 c-8.077,8.077-8.077,21.172,0,29.249l147.074,147.074c4.038,4.039,9.332,6.058,14.625,6.058c5.293,0,10.587-2.019,14.625-6.059 l323.562-323.562C514.019,100.767,514.019,87.672,505.943,79.594z" strokeWidth="1" className="fill"/></svg>
                                  <span className="label">signed:</span>
                                  <span className="value">{convertTimestamp(info._signedA)}</span>
                                </span>
                              : account.toLowerCase() === info._partA.toLowerCase()
                                ? signLoading === ind + 'A'
                                  ? <span className="cell flash">
                                      <span className="label">signing...</span>
                                      <span className="value">&nbsp;</span>
                                    </span>
                                  : <span className="cell">
                                      <span className="sign" onClick={(e) => contratoSign(e, ind, 'A')}><svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512"><g className="fill"><path d="M464.465,47.417C433.887,16.84,393.234,0,349.99,0c-43.243,0-83.898,16.84-114.476,47.417L76.741,206.19 c-3.878,3.879-6.058,9.14-6.058,14.625v199.937c0,11.423,9.26,20.682,20.682,20.682h199.936c5.496,0,10.766-2.187,14.647-6.079 l158.516-158.987c30.578-30.578,47.417-71.233,47.417-114.476C511.882,118.65,495.043,77.995,464.465,47.417z M435.215,247.118 c-0.006,0.007-0.014,0.015-0.021,0.022L282.717,400.07H112.048V229.382L264.764,76.667c22.765-22.765,53.033-35.302,85.226-35.302 s62.461,12.537,85.225,35.301c22.764,22.764,35.302,53.032,35.302,85.226C470.517,194.086,457.98,224.353,435.215,247.118z"/><path d="M364.732,147.386c-8.077-8.077-21.172-8.077-29.249,0L6.175,476.693c-8.077,8.077-8.077,21.172,0,29.249 C10.214,509.981,15.506,512,20.8,512c5.293,0,10.587-2.02,14.625-6.058l329.307-329.308 C372.808,168.558,372.808,155.463,364.732,147.386z"/><path d="M373.629,305.982H185.453c-11.422,0-20.682,9.259-20.682,20.682c0,11.423,9.26,20.682,20.682,20.682H373.63 c11.423,0,20.681-9.259,20.681-20.682C394.311,315.241,385.052,305.982,373.629,305.982z"/></g></svg></span>
                                    </span>
                                : <span className="cell">
                                    <span className="value">&nbsp;</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512"><path d="M505.943,79.594c-8.077-8.077-21.172-8.077-29.249,0L167.755,388.532L35.306,256.083c-8.076-8.077-21.172-8.077-29.248,0 c-8.077,8.077-8.077,21.172,0,29.249l147.074,147.074c4.038,4.039,9.332,6.058,14.625,6.058c5.293,0,10.587-2.019,14.625-6.059 l323.562-323.562C514.019,100.767,514.019,87.672,505.943,79.594z" className="fill"/></svg>
                                    <span className="label">not signed</span>
                                  </span>
                            }
                          </div>
                          <div className="part" data-letter="B" data-signed={isSigned(info._signedB)}>
                            <span className="cell">
                              <span className="label">participant B:</span>
                              <span className="value">{shortAddr(info._partB)}</span>
                            </span>
                            {isSigned(info._signedB)
                              ? <span className="cell">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512"><path d="M505.943,79.594c-8.077-8.077-21.172-8.077-29.249,0L167.755,388.532L35.306,256.083c-8.076-8.077-21.172-8.077-29.248,0 c-8.077,8.077-8.077,21.172,0,29.249l147.074,147.074c4.038,4.039,9.332,6.058,14.625,6.058c5.293,0,10.587-2.019,14.625-6.059 l323.562-323.562C514.019,100.767,514.019,87.672,505.943,79.594z" className="fill"/></svg>
                                  <span className="label">signed:</span>
                                  <span className="value">{convertTimestamp(info._signedB)}</span>
                                </span>
                              : account.toLowerCase() === info._partB.toLowerCase()
                                ? signLoading === ind + 'B'
                                  ? <span className="cell flash">
                                      <span className="label">signing...</span>
                                      <span className="value">&nbsp;</span>
                                    </span>
                                  : <span className="cell">
                                      <span className="sign" onClick={(e) => contratoSign(e, ind, 'B')}><svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512"><g className="fill"><path d="M464.465,47.417C433.887,16.84,393.234,0,349.99,0c-43.243,0-83.898,16.84-114.476,47.417L76.741,206.19 c-3.878,3.879-6.058,9.14-6.058,14.625v199.937c0,11.423,9.26,20.682,20.682,20.682h199.936c5.496,0,10.766-2.187,14.647-6.079 l158.516-158.987c30.578-30.578,47.417-71.233,47.417-114.476C511.882,118.65,495.043,77.995,464.465,47.417z M435.215,247.118 c-0.006,0.007-0.014,0.015-0.021,0.022L282.717,400.07H112.048V229.382L264.764,76.667c22.765-22.765,53.033-35.302,85.226-35.302 s62.461,12.537,85.225,35.301c22.764,22.764,35.302,53.032,35.302,85.226C470.517,194.086,457.98,224.353,435.215,247.118z"/><path d="M364.732,147.386c-8.077-8.077-21.172-8.077-29.249,0L6.175,476.693c-8.077,8.077-8.077,21.172,0,29.249 C10.214,509.981,15.506,512,20.8,512c5.293,0,10.587-2.02,14.625-6.058l329.307-329.308 C372.808,168.558,372.808,155.463,364.732,147.386z"/><path d="M373.629,305.982H185.453c-11.422,0-20.682,9.259-20.682,20.682c0,11.423,9.26,20.682,20.682,20.682H373.63 c11.423,0,20.681-9.259,20.681-20.682C394.311,315.241,385.052,305.982,373.629,305.982z"/></g></svg></span>
                                    </span>
                                : <span className="cell">
                                    <span className="value">&nbsp;</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512"><path d="M505.943,79.594c-8.077-8.077-21.172-8.077-29.249,0L167.755,388.532L35.306,256.083c-8.076-8.077-21.172-8.077-29.248,0 c-8.077,8.077-8.077,21.172,0,29.249l147.074,147.074c4.038,4.039,9.332,6.058,14.625,6.058c5.293,0,10.587-2.019,14.625-6.059 l323.562-323.562C514.019,100.767,514.019,87.672,505.943,79.594z" className="fill"/></svg>
                                    <span className="label">not signed</span>
                                  </span>
                            }
                          </div>
                          <div className="descr">
                            <span className="label">description:</span>
                            <span className="value">{info._description}</span>
                          </div>
                        </div>
                    } else {
                      return <div className="contrato" key={i} data-index={ind}>
                        <div className="loading flash">loading...</div>
                      </div>
                    }
                  })}
                </div>
              </div>
            : <div className="block fadeInUp">
                <div className="center">You don't have contratos yet</div>
              </div>
          }
        </>}
      </div>
    </div>
  )
}

export default App;
